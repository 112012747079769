<template>
  <div style="padding-top: 5px">
    <DeptTable />
    <DeptPagination />
  </div>
</template>

<script>
import DeptTable from './DeptTable';
import DeptPagination from './DeptPagination';
export default {
  name: 'DeptContainer',
  components: { DeptPagination, DeptTable },
};
</script>
