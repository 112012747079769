<template>
  <div>
    <!--  这里直接用扩展头部组件了，再包一层就难以处理了  -->
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'部门管理'"
      :icon="'el-icon-collection-tag'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="deptData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
          >
            <el-input v-model="deptData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="dept_store.dept_info"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </Table>
  </div>
</template>

<script>
import FilterHeader from '../common/FilterHeader';
import TableHeader from '../common/TableHeader';
import Table from '../common/Table';
import { mapState } from 'vuex';
import {
  DELETE_DEPT_INFO,
  GET_DEPT_INFO,
  INSERT_DEPT_INFO,
  UPDATE_DEPT_INFO,
} from '../../store/type';
import { showMsg } from '../../util/msg';

export default {
  name: 'DeptTable',
  components: {
    FilterHeader,
    Table,
    TableHeader,
  },
  data() {
    return {
      columnConfig: [{ prop: 'name', label: '部门名称' }],
      showDelBtn: false,
      delData: [],
      deptData: {},
      rowData: {},
    };
  },
  methods: {
    deleteTableData() {
      this.$store.dispatch(DELETE_DEPT_INFO, this.delData);
      showMsg('success', '删除成功！');
    },
    insertCustomer() {
      this.$store.dispatch(INSERT_DEPT_INFO, this.deptData);
      showMsg('success', '插入成功！');
    },
    updateData() {
      this.$store.dispatch(UPDATE_DEPT_INFO, this.rowData);
      showMsg('success', '更新成功！');
    },
    getDelData(val) {
      this.showDelBtn = val.length > 0;
      this.delData = val;
    },
    editData(data) {
      this.rowData = data;
    },
  },
  computed: {
    ...mapState({
      dept_store: state => state.dept_store,
    }),
  },
  created() {
    this.$store.dispatch(GET_DEPT_INFO);
  },
};
</script>

<style scoped></style>
